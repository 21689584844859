
 /* *{
    margin: 0;
} */
/* .grow {
    flex-grow: 1;
  }  */
.dropdown-menu{
    background-color: black;
}

@media only screen and (max-width: 1440px) {
    
}
@media only screen and (max-width: 1200px) {
    .ines{
        background-color: red;
    }
}
@media only screen and (max-width: 992px) {
    
}
@media only screen and (max-width: 768px) {
    
}
@media only screen and (max-width: 425px) {
    
}

