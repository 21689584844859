.mostformation::before {
  content: none;
}
.mostformation .item{
  margin-top: 55px;
}
/* .count {
  padding: 15px;
  background-color: #212730;
  position: absolute;
  z-index: 999;
  width: 85px;
  height: 132px;
  right: 0;
  top: -50px;
  color: var(--bs-blue);
  font-size: 88px;
  text-align: center;
  font-weight: bolder;
} */