.form-check-label{
    padding-left: 12px;
}
.btn-gy{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 12px 18px;
}
@media screen and (max-width:767px) {
    .mtb-10{
        margin: 10px 0px;
    }
}
@media screen and (max-width:768px) {
    .none{
        display: none;
    }
}
@media screen and (min-width:424px) and (max-width:768px) {
   .form-label{
       height: 48px !important;
   }
}
