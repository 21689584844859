.profil .table {
    background-color: #212730;
    overflow-y: scroll;
}

.profil .table.tr {
    color: white !important;
}

.profil .table.td {
    color: white !important;
}

.profil .table.th {
    text-align: left;
}

.profil .table-dark {
    --bs-table-bg: #212730;

}

.search-filtre input {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    background-color: var(--input-color);
    border: 0;
    color: white;

}

.nav-icon-search {
    position: absolute;
    top: 5px;
    right: 10px;
}

.search-filtre input:focus {
    background-color: var(--input-color);

}

.mdb-dataTable {
    color: white !important;
}

.search-filtre {
    margin: 20px 40px;
}

.button-filtre {
    background-color: #212730;
    color: #006FFF;
}

.search-filtre .bd-highlight {
    position: relative;
}

/*************Push 11-05-22************/
.mobile {
    display: none !important;
}

.desktop {
    display: block !important;
}

.mobileSelect {
    display: none;
}

@media screen and (max-width: 768px) {
    .mobile {
        display: block !important;
        margin-top: 45px !important;
    }

    .desktop {
        display: none !important;
    }

    .MobileTable {
        display: block !important;
    }

    .DesktopTable {
        display: none !important;
    }

    .mobileSelect {
        display: block;
        padding: 10px;

    }

    .mobileSelect .form-select {
        background-image: url("../../assets/icons/select.svg") !important;
    }

    .profil .search-filtre {
        justify-content: flex-end;
        margin: 20px 21px !important;
    }

    .mobileSelect .dropdown {
        float: left !important;
        background-color: #3A4048 !important;
        margin-left: auto !important;
        margin-right: auto !important;

    }

    .mobileSelect .dropdown {
        width: 100% !important;

    }

    .profil .search-filter .bd-highlight {
        width: 100% !important;
    }

    .mobile {
        height: 39% !important;
    }

    .profilGrid {
        margin-top: 20px !important;
    }

    .profilGrid .card {

        min-height: 108px;
    }

    .mobile .img {
        width: 43%;
    }

    .gridBP {
        display: inline-flex;
        justify-content: flex-end;
    }

    .mobile .img {
        position: inherit !important;
    }

    .profil .btn-primary {
        background-color: transparent;
        color: #006FFF;
        font-size: 16px;
        font-weight: 600;
    }

    .Tableformation {
        border-bottom: 2px solid #FFFFFF;
    }

    .Tableformation label {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        margin: 15px;
    }

    .formation-i label {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin: 15px;
    }

    .formation-i {
        border-bottom: 1px solid #373D45;
    }

}

@media screen and (max-width: 426px) {
    .mobile .img {
        width: 50%;
    }

    .mobile {
        height: 37% !important;
    }

    .profil .search-filter .bd-highlight {
        width: 100% !important;
    }

    .mobile .banner-box {
        width: 50%;
        margin-left: 15px;
    }

    .profil table {
        position: relative !important;
    }

    .search-filtre {
        justify-content: flex-end !important;
        margin: 20px 21px !important;
    }

    .search-filter .btn-primary {
        color: #0d6efd;
        background-color: transparent;
        border-color: #0d6efd;
    }

    .banner-profil {
        margin-right: 0px !important;
    }
}

#firstColor {
    background: linear-gradient(90deg, rgba(1, 111, 253, 1) 0%, rgba(17, 57, 108, 1) 62%, rgba(30, 46, 68, 1) 100%) !important;
}

#secondColor {
    background: #151C25 !important
}

.firstColorCadre {
    background: #151C25 !important;
}

.secondColorCadre {
    background: #1e2e45 !important;
}

.firstColorCadre:hover {
    background: #006fff !important;
}

.secondColorCadre:hover {
    background: #006fff !important;
}
