tr:hover{
    /*color: var(--primary-color)!important;*/
}
.email .table-dark{
    text-align: center;
    --bs-table-bg: #212730; 
   
   }
.title-email{
    font-size: 25px;
}
[data-column-id="tableCell"].rdt_TableCell , .rdt_TableHeadRow div, .rdt_TableRow div,  .rdt_ExpanderRow  {
    background-color: #212730 !important;
    color: white !important;
    font-size:16px;
    border-bottom: 1px solid #373D45;
    font-family: 'Montserrat';
  }
  [data-column-id="tableCell"].rdt_TableCol  {
    background-color: #212730;
    color: white;
    font-size:16px;
    border-bottom: 3px solid #373D45 !important;
    font-family: 'Montserrat';
  }
  .email tr:hover td {
    color: var(--primary-color);
    cursor: pointer;
}
/*  .mobileDatatable{
      display: none;
      margin:65px 0px ;
  }
  .desktopTable{
      display: block;
  }
  @media screen and (max-width:426px) {
    .mobileDatatable{
        display: block !important;
    }
    .desktopTable{
        display: none !important;
    }   
  }*/