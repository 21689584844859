
.displayBlock{
    display: block !important;
}
.modaltitle{
font-size: 23px ;
line-height: 28px;
}
.provider .modal-footer{
    justify-content: space-between !important;
}
.error{
    border: 1px solid #CC493C;
}
.text-danger{
    color:#CC493C !important;
    margin-top:5px;
    margin-top:10px;
    font-size: 14px;
    font-weight: 500;

}
.errorinput{
    border:"1px solid #CC493C" !important; 
}
.themeSelect svg{
  height: 20px;
  width: 20px;
  margin-left:10px
}

@media (min-width: 576px){
.provider .modal-dialog {
    max-width: 684px !important;
    
}   
}
