#content_player{
    /* margin-top: 2rem; */
    width: 100%!important;
    text-align: -webkit-center;
}

#mp4-player{
    width: 100%!important;
    height: 100%!important;
}

#mp4-player audio, #content_player audio{
    height: 30vh!important;
}

.videomodal .modal-dialog {
    width: 100%!important;
}

#unite_title{
    /* position: absolute;
    top: -2rem; */
    color: #fff;
    text-align: left;
    font-size: 2rem;
    line-height: 33px;
}

#unite_footer{
    width: auto!important;
    position: absolute;
    right: 8rem;
}

#unite_title .elearning{
    font-weight: 700;
}

#unite_title .chapitre{
    font-weight: 100;
    font-size: 1.5rem;
    line-height: 2rem;
}

#content_player object, #content_player>div{
    width: 100%!important;
    height: calc(100vh - 200px)!important;
}