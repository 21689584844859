@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;900&family=Oswald:wght@200;400;600;700&display=swap');

@font-face {
  font-family: "Guyhoquet";
  src: url("./assets/font/guy-hoquet/Guyhoquet-Bold.otf") format("openType");
  src: url("./assets/font/guy-hoquet/Guyhoquet-Bold.woff") format("woff"),
  url("./assets/font/guy-hoquet/Guyhoquet-Bold.woff2") format("woff2");
}

:root {
  /* --header-height: 3rem; */
  --primary-color: #006FFF;
  --white-color: #FFFFFF;
  --secondary-color: #212730;
  --black-color: #151C25;
  --card-color: #1E2E45;
  --danger-color: #CC493C;
  --warning-color: #FFB13B;
  --success-color: #3EA279;
  --input-color: #3A4048;
  --input-text-color: #CCCCCC;
  --admin-background: #F1F9FF;

  /* CK editor */
  --ck-border-radius: 4px;
  --ck-font-size-base: 14px;
  --ck-color-base-border: hsl(300, 1%, 22%) !important;
  --ck-color-base-background: transparent !important;
  --ck-custom-background: hsl(270, 1%, 29%) !important;
  --ck-custom-foreground: hsl(255, 3%, 18%) !important;
  --ck-custom-border: hsl(300, 1%, 22%) !important;
  --ck-custom-white: hsl(0, 0%, 100%) !important;
  --ck-color-base-foreground: var(--ck-custom-background) !important;
  --ck-color-focus-border: hsl(208, 90%, 62%) !important;
  --ck-color-text: hsl(0, 0%, 98%) !important;
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2) !important;
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1) !important;
  /*--ck-color-button-default-background: var(--ck-custom-background) !important;*/
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%) !important;
  --ck-color-button-default-active-background: hsl(270, 2%, 20%) !important;
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%) !important;
  --ck-color-button-default-disabled-background: var(--ck-custom-background) !important;
  --ck-color-button-on-background: var(--ck-custom-foreground) !important;
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%) !important;
  --ck-color-button-on-active-background: hsl(255, 4%, 14%) !important;
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%) !important;
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground) !important;
  --ck-color-button-action-background: hsl(168, 76%, 42%) !important;
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%) !important;
  --ck-color-button-action-active-background: hsl(168, 76%, 36%) !important;
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%) !important;
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%) !important;
  --ck-color-button-action-text: var(--ck-custom-white) !important;
  --ck-color-button-save: hsl(120, 100%, 46%) !important;
  --ck-color-button-cancel: hsl(15, 100%, 56%) !important;
  --ck-color-dropdown-panel-background: var(--ck-custom-background) !important;
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground) !important;
  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background) !important;
  --ck-color-split-button-hover-border: var(--ck-custom-foreground) !important;
  --ck-color-input-background: var(--ck-custom-background) !important;
  --ck-color-input-border: hsl(257, 3%, 43%) !important;
  --ck-color-input-text: hsl(0, 0%, 98%) !important;
  --ck-color-input-disabled-background: hsl(255, 4%, 21%) !important;
  --ck-color-input-disabled-border: hsl(250, 3%, 38%) !important;
  --ck-color-input-disabled-text: hsl(0, 0%, 78%) !important;
  --ck-color-labeled-field-label-background: var(--ck-custom-background) !important;
  --ck-color-list-background: var(--ck-custom-background) !important;
  --ck-color-list-button-hover-background: var(--ck-custom-foreground) !important;
  --ck-color-list-button-on-background: hsl(208, 88%, 52%) !important;
  --ck-color-list-button-on-text: var(--ck-custom-white) !important;
  --ck-color-panel-background: var(--ck-custom-background) !important;
  --ck-color-panel-border: var(--ck-custom-border) !important;
  --ck-color-toolbar-background: var(--black-color) !important;
  --ck-color-toolbar-border: var(--ck-custom-border) !important;
  --ck-color-tooltip-background: hsl(252, 7%, 14%) !important;
  --ck-color-tooltip-text: hsl(0, 0%, 93%) !important;
  --ck-color-image-caption-background: hsl(0, 0%, 97%) !important;
  --ck-color-image-caption-text: hsl(0, 0%, 20%) !important;
  --ck-color-widget-blurred-border: hsl(0, 0%, 87%) !important;
  --ck-color-widget-hover-border: hsl(43, 100%, 68%) !important;
  --ck-color-widget-editable-focus-background: var(--ck-custom-white) !important;
  --ck-color-link-default: hsl(190, 100%, 75%) !important;
}

::-webkit-calendar-picker-indicator {
  background-image: none;
}

/*input[type="date"] {*/
/*  background-image: url('./assets/icons/CalendarBlue.svg') !important;*/
/*  background-repeat: no-repeat;*/
/*  background-position: right 7px center;*/
/*}*/

input[type="time"] {
  background-image: url('./assets/icons/Time.svg') !important;
  background-repeat: no-repeat;
  background-position: right 7px center;
}

.react-bootstrap-table {
  overflow-y: scroll;
}

select:disabled {
  background-color: #808080 !important;
  color: #4C4C4C !important;
  background-image: url('./assets/icons/disabledSelect.svg') !important;
}

.secondaryModal {
  border: 1px solid #006FFF;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 8px;
  color: #006FFF;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 15px;
}

.secondaryModal:disabled {
  border: 1px solid #808080;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 8px;
  color: #808080;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

button:disabled {
  cursor: not-allowed;
}

.modal .btn-primary {
  padding: 12px 18px;
}

.float-right {
  float: right !important;
}

.deleteModal .modal-footer {

  justify-content: space-between !important;
}

.px10 {
  padding: 0px 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-135 {
  margin-bottom: 135px;
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.flex-start {
  justify-content: flex-start;
}

.flexgy {
  display: flex;
  justify-content: flex-end;
}

.grecard {
  background-color: #212730 !important;
}

.cardUnit {
  background-color: #151c25 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.session1 .cardUnit, .session1 .card-body {
  background-color: #212730 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.session1 .cardUnit:hover .card-body {
  background-color: #212730 !important;
}

.cardUnit:hover .card-body {
  background-color: #151c25 !important;
}

.flex {
  display: flex;
}

.height43 {
  height: 43px !important;
  /* display: flex;*/
  padding: 0 18px;
  align-items: center;
}

.btn.height43 {
  display: flex;
  justify-content: space-around;
}

.mt-20 {
  margin-top: 20px;
}

.form-select, .form-control {
  background-color: #3A4048 !important;
  color: #FFFFFF !important;
  border-radius: 0px !important;
  border: 1px solid #3A4048 !important;
  font-weight: 600;

}

.form-control:focus-visible {
  font-weight: 600;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  background-color: #212730 !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}

body ::placeholder {
  font-style: italic;
  font-weight: 400;
}

.form-select:focus {
  border-color: 0 !important;
  box-shadow: none !important;
  font-weight: 400 !important;
}

h1, h2, h3, h4, h5 {
  color: var(--white-color);
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-family: "Guyhoquet", sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 46px;
}

h2 {
  font-family: "Guyhoquet", sans-serif;
  font-size: 33px;
  font-weight: bold;
  line-height: 40px;
}

h3 {
  font-family: "Guyhoquet", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
}

h4 {
  font-size: 23px;
  font-weight: 600;
  line-height: 34px;
}

h5 {
  font-size: 19px;
  font-weight: bold;
  line-height: 25px;
}

table tr {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  height: 49px;
}

th {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  height: 49px;
  vertical-align: middle !important;
  margin-top: 13px !important;
  margin-bottom: 14px !important;
}

td {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  vertical-align: middle !important;
  margin-top: 13px !important;
  margin-bottom: 14px !important;

}

.table > :not(caption) > * > * {
  padding: 0 10px;
}

.sub-nav li.nav-item .nav_link {
  margin-bottom: 0;
}

.App {
  text-align: center;
}

.btn {
  border-radius: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.searchinput .form-control, .searchinput .form-control:hover, .searchinput .form-control:focus {
  background-color: #212730 !important;

  border: none !important;

}

.search input {
  padding-left: 70px !important;
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #ffff;
  border-radius: 0px;
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}

.search input::placeholder {
  font-size: 44px;
  font-weight: bold;

}

.disable::before, .disable h2::after {
  content: none !important;
}

.table-dark {
  --bs-table-bg: var(--dark-color) !important;
  --bs-table-hover-bg: #59A1FF !important;
}

.tbody tr th {
  padding-top: 16px;
}

td {
  /* line-height: 3 !important;*/
}

.simpletext img {
  width: 18px !important;
}

@media only screen and (min-width: 1440px) {
  body {
    padding-left: calc(var(--nav-width) + 40px) !important;
    padding-right: calc(var(--nav-width) + -40px) !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282C34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.info-inline .list-inline-item {
  position: relative;
}

.info-inline li.list-inline-item::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 12px;
  background: #6C6C6C;
  right: 5px;
  top: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
/* ::-webkit-scrollbar {
  width: 3px;
}
/* Track */
body ::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

.searchTitle h4 {
  line-height: 34px;
  padding-left: 0;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 28px;
}

.search-title span {
  color: var(--primary-color);
}

[type="search"]::placeholder {
  color: hsl(0, 0%, 80%);
}

/* .input-group:hover {
  border:1px solid #59A1FF !important;
}
.input-group:focus {
  border:1px solid #1372ee !important;
}
input[type="search"]:hover{
  border: none !important;
} */
[type="search"] {
  background: url('./assets/icons/Search.svg');
  background-repeat: no-repeat;
  /* background-size: 7%; */
  background-position: 98%;
  padding-right: 5px;
}

[type="search"]::-webkit-search-cancel-button {
  display: none !important;
}

.form-check .form-check-input {
  /*border-color: var(--primary-color) !important;*/
  background-color: var(--secondary-color) !important;
  border: 2px solid #006FFF !important;
  border-radius: 0 !important;
  width: 18px;
  height: 18px;
  /*  margin-top: 17px !important;*/
  margin-bottom: 12px !important;
}

.form-check .form-check-input:checked {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.breadcrumb-item a {
  color: var(--white-color);
  font-weight: 400;
  font-size: 14px;
}

.breadcrumb-item.active {
  color: var(--white-color) !important;
  font-weight: 600;
}

.breadcrumb-item a:hover {
  color: var(--white-color);
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: none !important;
}

.headupdate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nopad {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.round {
  background-color: #006fff !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
}

.fade.toast.show {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 9999;
}

.Csuccess {
  background: rgb(227, 247, 225) !important
}

.Cerror {
  background: rgb(247, 225, 230) !important
}

.Cerror-jago {
  background: red !important;
  color: #fff !important;
}

.Csuccess-jago {
  background: green !important;
  color: #fff !important;
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .card .card-title {
    font-size: 16px !important;
  }

  /* .search-title {
    font-size: 26px;
} */
  .body-details .search-title {
    font-size: 16px !important;
  }

  @media screen and (max-width: 425px) {
    .form-check-input {
      width: 27px !important;
      height: 18px !important;
    }

    .flexgy {
      flex-direction: column;
    }

    .flexgy .flex {
      justify-content: space-evenly !important;
    }
  }

}

@media only screen and (max-width: 578px) {
  .flexgy {
    flex-direction: column;
  }

  .flexgy .flex {
    justify-content: space-evenly !important;
  }

  .myxs {
    margin: 10px 0 !important;
  }
}

@media only screen and (max-width: 425px) {
}

.btn-primary {
  font-size: 16px !important;
  font-weight: 600 !important;
  /* line-height: 19px !important;*/
  font-family: 'Montserrat', sans-serif !important;
  background-color: #006FFF;
}

.btn-primary:hover {
  background-color: #59A1FF !important;
}

.btn-primary:focus {
  background-color: #0061DF !important;
}

.form-control:hover {
  border: 1px solid #59A1FF !important;
  box-shadow: none !important;
}

.form-control:focus {
  border: 1px solid #006FFF !important;
  box-shadow: none !important;
}

.errorinput {
  border: 1px solid #CC493C !important;
}

.modal-content {
  padding: 30px !important;

}

.videomodal .modal-body {
  text-align: center;
}

.videomodal .textemodal {
  font-size: 18px;
  font-weight: 200;
}

.videomodal .float-end {

  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.videomodal .p {
  line-height: 3;
}

.videomodal .modal-dialog {
  width: 70% !important;
  max-width: 100% !important;
}

.deleteModal .modal-dialog {

  max-width: fit-content !important;
}

.btn-close:focus {
  box-shadow: none !important;
}

.simpletext {
  font-family: 'Montserrat', sans-serif !important;
  /* background-color: #212730!important; */
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}

.simpletext ul li {
  list-style: none;
  margin: .5rem 0;
  position: relative;
}

.simpletext ul li:before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("./assets/icons/Plus.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: -33px;
}

.no-padding p {
  padding-left: 0 !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 22px !important;
  font-weight: 700 !important
}

.no-padding .simpletext p {
  padding-left: 0 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  display: flex;
}

span.nameAgence {
  color: white;
  text-transform: lowercase;
}

.calendar .form-control {
  width: auto;
}

.calendar {
  text-align: right;
}

.react-daterange-picker__wrapper {
  border: none !important;
}

.react-daterange-picker__inputGroup__input {
  color: white !important;
}

.react-calendar {
  background: #1e2e45 !important;
  color: white !important;
}

.react-calendar span, .react-calendar button {
  color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #9500ff !important;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #006fff !important;

}

table .form-switch {
  padding-left: 1.5em !important;
}

.no-style div {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  height: auto !important;
  min-height: auto !important;
}

.no-style svg {
  /* background: red; */
  fill: #006fff;
  width: 25px;
  height: 25px;
  padding-top: 5px;
}

.no-style ul {
  background: #3a4048;
  color: white;
}

.pHieT .selected {
  background-color: #1e2e45;
  cursor: pointer;
}

.no-style input {
  color: white;
}

.react-daterange-picker__button {
  filter: invert(31%) sepia(38%) saturate(7483%) hue-rotate(204deg) brightness(92%) contrast(103%);
}

.react-daterange-picker__button:hover {
  filter: invert(31%) sepia(38%) saturate(7483%) hue-rotate(204deg) brightness(92%) contrast(103%);
}

.card-body {
  cursor: pointer;
}

.look-like-link {
  color: var(--bs-link-color);
  cursor: pointer;
}
