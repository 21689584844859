
*{
    margin: 0;
}
.modal-content{
    background-color: var(--black-color) !important;
   /* margin-top: 40%;*/
}
 @media (min-width: 992px)
{
    .profil .modal-lg, .modal-xl {
    max-width: 900px !important;
}}
.form-check-input{
   margin-top:3px !important; 
}
.ines{
    background-color: green;
}
.modal-header {
    color : white;
}
.modal-footer {
    border-top: 0;
}
.modal-header{
    border-bottom: 0;
}
.label-popup{
    size:16px;
}
.label-popup-taux .search-title{
    font-size: 18px;
}
.modal-body .form-check-input{
    height: 20px;
    width: 20px;
    border: 2px solid var(--primary-color);
    background-color: var(--black-color);
}
.modal-body .form-control .drop{
    border: var(--input-color);
}
.modal-header .btn-close{
    background: transparent url('../../assets/icons/closeModal.svg') no-repeat
}
.btn-check:active+.btn-primary, 
.btn-check:checked+.btn-primary, 
.btn-primary.active,
 .btn-primary:active, 
 .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3A4048;
    border-color: #3A4048;
}
.modal-dialog .dropdown button, .modal-dialog .input{
    width: 100%;
    text-align: left;
}
.dropdown-menu {
    background-color: var(--input-color);
    width: 100%;
}
.dropdown-menu .dropdown-item{
    color: var(--white-color);
}
.dropdown-item:focus {
    color: var(--black-color);

}
.form-check-label{
    color: white;
}
.dropdown-toggle::after {
    position: absolute;
    right: 6px;
    top: 14px;
    right: 7px;
    top: 7px;
    width: 20px;
    height: 20px;
    vertical-align: 0.255em;
    content: "";
    background: url(../../assets/icons/Dropdown-popup.png);
    border: 0;
    /* right: 0; */
}
.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--black-color);
}
 .modal-body input[type="date"]{
    color: #fff;
    background-color: var(--input-color);
    border-color: var(--input-color);
}

.vidIframe {
    min-width: 1080px;
    min-height: calc(100vh - 250px);
}
.special-width-popup .modal-dialog {
    width: 100%!important;
    max-width: 50%;
}
.filter .modal-footer {
    justify-content: space-between;
}
@media only screen and (max-width: 1440px) {
    
}
@media only screen and (max-width: 1200px) {
    .ines{
        background-color: red;
    }
    .special-width-popup .modal-dialog {
        width: 95%;
    }
}
@media only screen and (max-width: 992px) {
    
}
@media only screen and (max-width: 768px) {
    
}
@media only screen and (max-width: 425px) {
    
}

