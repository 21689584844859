.bg-dark {
    --bs-bg-opacity: 1;
    background-color: var(--black-color) !important;
}
.h4-size h4{
    font-size: 28px;
    font-weight: 600;
}
.form-aria{
    background-color: #212730;
}
.cost{
    background-image: url('../../../../../assets/icons/euro.png') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}
.lists p{
    font-size: 19px;
}
/*.square{
    margin-left: 13px;
    height: 43px;
    width: 43px;
    border: 1px solid var(--primary-color);
    text-align: center;
    line-height: 40px;
}
.square button{
    padding-left: 6px;
}*/
.lists-filter .input-group-append .btn-outline-dark {
    background-color: var(--input-color);
    border: 1px solid var(--input-color);
}
.card:hover .card-body{
    background-color: transparent;
}
.accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion-item {
    padding: 20px 20px 0;
    margin-bottom: 25px;
    background-color: var(--card-color);
    border: 0;
}
.accordion-header{
    font-family: 'Montserrat';
}
.accordion-button{
    background-color: var(--card-color);
    display: block;
    color: var(--white-color);
}
.accordion-button::after {
    background: url('../../../../../assets/icons/dropdown.png') no-repeat;
    position: absolute;
    right: 60px;
    top: 35%;
}
.accordion-button .header-text{
    font-size: 19px;
    font-weight: 700;
    text-transform: capitalize;
}
.accordion-button:focus {
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    color: var(--white-color);
    background-color: var(--card-color);
    box-shadow: none;
}
.accordion-info{
    font-weight: 400;
    font-size: 14px !important;
    font-family: 'Montserrat';
}
.accordion-body .card-body, .accordion-body .card{
    background-color: var(--secondary-color);
}
.lists .card-footer .d-flex{
    justify-content: end;
}
