.cost{
    background-image: url('../../assets/icons/euro.png') !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
}
.elearning.body-details{ 
    background: linear-gradient(180deg, #151c25 97%, #151c2500 30%, rgba(9,9,121,0) 63%);
  }
.elearning-square{
    background-color: rgb(30, 46, 69);
    margin: auto;
    border: #ffffff solid 1px;
    position: relative;
}
.elearning-bottom::after {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: rgb(30, 46, 69);
    bottom: -11px;
    left: 45%;
    border-top: #ffffff solid 1px;
    border-left: #ffffff solid 1px;
    transform: rotate(225deg);
  }
  .elearn .accordion-button::before{
  display: none !important;
 }
 .elearn .accordion-button::after{
  display: none !important;
 }
  .chart{
    padding: 10px 70px 20px;
  }

 .form-switch .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.form-switch .form-check-input:checked {
  background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.rowgy{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width:768px) {
  .rowgy{
    display: flex;
    flex-direction: column !important;
  }
}
/*
.form-switch .form-check-input:checked {
  background-position: left center;
} */
  
